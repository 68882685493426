import React, { InputHTMLAttributes, forwardRef, useState } from "react";
import classNames from "classnames";
import Icon from "~/assets/icons/Icon";
import { InputError, ariaControlledBorder } from "../InputError";
import {
  FieldError,
  UseFormRegisterReturn,
  RefCallBack,
  RegisterOptions,
} from "react-hook-form";

export type UncontrolledInputProps = {
  id: string;
  label: string;
  placeholder: string;
  error?: FieldError | undefined;
  type?: string;
  className?: string;
  initialValue?: string | number;
  asRow?: boolean;
  registerOptions?: RegisterOptions;
} & Partial<UseFormRegisterReturn> &
  InputHTMLAttributes<HTMLInputElement>;

const UncontrolledInput = forwardRef(
  (
    {
      id,
      label,
      placeholder,
      error,
      type = "text",
      className,
      initialValue,
      asRow,
      registerOptions,
      ...restProps
    }: UncontrolledInputProps,
    ref
  ) => {
    const [inputType, setInputType] = useState(
      type === "currency" ? "number" : type
    );

    function onShowPassword(): void {
      if (inputType == "text") {
        setInputType("password");
      } else {
        setInputType("text");
      }
    }

    return (
      <div
        className={classNames(asRow && "grid grid-cols-[24px_1fr]", className)}
      >
        <label className="text-secondary-600 text-xs font-normal" htmlFor={id}>
          {registerOptions?.required ? label + "*" : label}
        </label>

        <div className="relative flex items-center">
          <input
            id={id}
            name={id}
            className={classNames(
              "w-full border border-secondary-200 rounded-lg pl-5 pr-5 py-3 text-secondary-800 placeholder:text-secondary-400 bg-transparent",
              type === "password" && "placeholder:translate-y-1",
              type === "currency" &&
                "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none",
              "disabled:opacity-50 disabled:cursor-not-allowed",
              "read-only:opacity-50 read-only:cursor-not-allowed read-only:bg-secondary-100",
              ariaControlledBorder
            )}
            autoFocus={Boolean(error)}
            aria-invalid={Boolean(error)}
            type={inputType}
            placeholder={placeholder}
            defaultValue={initialValue}
            ref={ref as RefCallBack}
            {...restProps}
          />
          {type === "password" && (
            <button
              className="absolute right-2 p-3"
              onClick={onShowPassword}
              data-testid="eye"
              type="button"
            >
              <Icon name="passwordEye" className="w-6 h-6" />
            </button>
          )}
          {type === "currency" && (
            <span className="absolute right-2 p-3 text-secondary-800">kr.</span>
          )}
        </div>
        <InputError>{error?.message}</InputError>
      </div>
    );
  }
);

export default UncontrolledInput;
