import { useState, ChangeEvent, DragEvent } from "react";
import { StrapiMedia } from "~/shared-types";
import { handleError } from "~/utils/rollbar";
import { showToast } from "~/utils/toast";
import { FileToUpload, uploadFiles } from "~/utils/uploadFiles";
import { Orientation, getMediaSize } from "~/utils/getMediaSize";
import { getDanishOrientation } from "~/utils/translateToDanish";
import { asyncSome } from "~/utils/asyncSome";
import { AxiosProgressEvent } from "axios";
import { orientateImageFile } from "~/utils/orientateImageFile";

export type UseFileUploadOptions = {
  sizeLimitMB: number;
  initialFiles?: StrapiMedia[];
  onChange: (file: StrapiMedia[] | null) => void;
  fileLimit?: number;
  acceptedOrientations?: Array<Orientation>;
  acceptedMimeTypes?: string[];
};

// Limited by Scalingo
export const MAX_FILE_SIZE_MB = 70;

export const updateUploadProgress = (
  currentFiles: FileToUpload[],
  upload: AxiosProgressEvent,
  file: FileToUpload
) => {
  const matchingFileIndex = currentFiles.findIndex((f) => f.name === file.name);

  if (matchingFileIndex === -1) return currentFiles;
  currentFiles[matchingFileIndex].upload = upload;

  return [...currentFiles];
};

const checkValidFileType = (type: string, acceptedMimeTypes?: string[]) => {
  if (!type.startsWith("image/") && !type.startsWith("video/")) return false;

  if (acceptedMimeTypes && !acceptedMimeTypes.includes(type)) return false;

  return true;
};

export const useFileUpload = ({
  sizeLimitMB,
  initialFiles,
  onChange,
  fileLimit = 1,
  acceptedOrientations = ["landscape", "portrait", "square"],
  acceptedMimeTypes,
}: UseFileUploadOptions) => {
  const translatedOrientation = acceptedOrientations.map(getDanishOrientation);

  const [uploadedFiles, setUploadedFiles] = useState<StrapiMedia[]>(
    initialFiles ?? []
  );
  const [filesToUpload, setFilesToUpload] = useState<FileToUpload[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const fileList = [...filesToUpload, ...uploadedFiles];

  const [dragState, setDragState] = useState<
    "" | "enter" | "incorrect" | "over-file-limit"
  >("");
  const [value, setValue] = useState("");

  const onDragEnter = (e: DragEvent<HTMLInputElement>) => {
    const items = e.dataTransfer?.items;
    if (!items) return;
    if (items.length > fileLimit) {
      return setDragState("over-file-limit");
    }

    for (let i = 0; i < items.length; i++) {
      const { kind, type } = items[i];

      if (kind === "string") return setDragState("incorrect");

      if (!checkValidFileType(type, acceptedMimeTypes))
        return setDragState("incorrect");
    }

    setDragState("enter");
  };

  const onDragLeave = () => setDragState("");

  const onError = (
    title = "Forkert type",
    description = "Filen har en forkert type."
  ) => {
    showToast("danger", title, description);
    setValue("");
    setDragState("");
    setIsUploading(false);
  };

  const onFileDrop = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);
    if (dragState === "incorrect") return onError();

    const { files } = e.target as HTMLInputElement;
    if (!files || files.length === 0) return;

    const fileArray = Array.from(files);
    if (fileArray.length > fileLimit)
      return onError(
        "For mange filer",
        `Du kan kun uploade ${fileLimit} fil(er).`
      );

    const maxLimitMB =
      MAX_FILE_SIZE_MB < sizeLimitMB ? MAX_FILE_SIZE_MB : sizeLimitMB;

    if (fileArray.some((file) => file.size > maxLimitMB * 1024 * 1024))
      return onError(
        "Filen er for stor",
        `Du kan uploade filer op til ${maxLimitMB} MB`
      );

    if (
      fileArray.some(
        (file) => !checkValidFileType(file.type, acceptedMimeTypes)
      )
    )
      return onError();

    const isWrongOrientation = await asyncSome(fileArray, async (file) => {
      const { orientation } = await getMediaSize(file);
      return !acceptedOrientations.includes(orientation);
    });
    if (isWrongOrientation)
      return onError(
        "Forkert format",
        `Du kan kun uploade medier i følgende formater: ${translatedOrientation.join(
          ", "
        )}`
      );

    const orientatedFiles: File[] = [];

    for (const file of fileArray) {
      orientatedFiles.push(await orientateImageFile(file, document));
    }

    setFilesToUpload((prev) => [...prev, ...orientatedFiles]);

    try {
      const uploadedFiles = await uploadFiles(orientatedFiles, (upload, file) =>
        setFilesToUpload((prev) => updateUploadProgress(prev, upload, file))
      );
      // istanbul ignore next
      const existingFiles = fileList.filter(
        (file) => "id" in file
      ) as StrapiMedia[];
      const uploadedFilesArray = [...uploadedFiles, ...existingFiles];

      // Remove uploaded files from filesToUpload
      setFilesToUpload((prev) => {
        return prev.filter((file) => !orientatedFiles.includes(file));
      });

      // Remove files over the file limit
      uploadedFilesArray.splice(fileLimit);

      onChange(uploadedFilesArray);
      setUploadedFiles(uploadedFilesArray);
    } catch (error) {
      showToast("danger", "Upload fejlede", "Prøv igen senere.");
      handleError(error as Error);
    }
    setDragState("");
    setIsUploading(false);
  };

  const onDeleteClick = async (fileId: number) => {
    const updatedList = fileList.filter(
      (file) => "id" in file && file.id !== fileId
    ) as StrapiMedia[];

    setUploadedFiles(updatedList);
    onChange(updatedList.length > 0 ? updatedList : null);
  };

  const onReorder = (index: number, direction: "up" | "down") => {
    if (direction === "up" && index === 0) return;
    if (direction === "down" && index === fileList.length - 1) return;

    const updatedList = fileList.slice() as StrapiMedia[];
    const [removed] = updatedList.splice(index, 1);
    updatedList.splice(index + (direction === "up" ? -1 : 1), 0, removed);

    setUploadedFiles(updatedList);
    onChange(updatedList);
  };

  return {
    fileList,
    dragState,
    onDragEnter,
    onDragLeave,
    onFileDrop,
    onDeleteClick,
    value,
    onReorder,
    isUploading,
  };
};
