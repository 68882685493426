import { UploadedMedia } from "./UploadedMedia";
import { UploadProgress } from "./UploadProgress";
import { FileToUpload, MediaFile } from "~/utils/uploadFiles";
import { StrapiMedia } from "~/shared-types";

const isStrapiMedia = (file: MediaFile) => "id" in file;

export function UploadList({
  fileList,
  onDeleteClick,
  onReorder,
  onExampleClick,
  showPrimary,
  aspectRatioClass,
}: {
  fileList: Array<MediaFile>;
  onDeleteClick: (fileId: number) => void;
  onReorder?: (index: number, direction: "up" | "down") => void;
  onExampleClick: (fileId: number) => void;
  showPrimary?: boolean;
  aspectRatioClass?: string;
}) {
  return (
    <>
      {fileList.length > 0 && (
        <div className="text-secondary-800">
          {fileList.map((file, index) =>
            isStrapiMedia(file) ? (
              <UploadedMedia
                key={"uploaded" + (file as StrapiMedia).id}
                onDeleteClick={onDeleteClick}
                onExampleClick={onExampleClick}
                media={file as StrapiMedia}
                onReorder={
                  onReorder
                    ? (direction) => onReorder(index, direction)
                    : undefined
                }
                isPrimary={showPrimary && index === 0}
                aspectRatioClass={aspectRatioClass}
              />
            ) : (
              <UploadProgress
                key={"progress" + (file as FileToUpload).name}
                name={(file as FileToUpload).name}
                upload={(file as FileToUpload).upload}
              />
            )
          )}
        </div>
      )}
    </>
  );
}
