import classNames from "classnames";
import { forwardRef, useState } from "react";
import Icon from "~/assets/icons/Icon";
import { StrapiMedia } from "~/shared-types";
import { UploadList } from "./UploadList";
import { FieldError } from "react-hook-form";
import { useFileUpload, UseFileUploadOptions } from "./useFileUpload";
import { MediaExampleDialog } from "./MediaExampleDialog";
import { BackgroundMedia } from "./BackgroundMedia";

type FileUploadProps = Omit<UseFileUploadOptions, "sizeLimitMB"> & {
  sizeLimitMB?: number;

  id: string;
  label: string;
  className?: string;
  compact?: boolean;
  browseText?: string;
  error?: FieldError;
  required?: boolean;
  enableReorder?: boolean;
  aspectRatioClass?: string;
  showPrimary?: boolean;
  acceptedFileTypes?: Array<"image/*" | "video/*">;
  hideBackgroundMedia?: boolean;
};

const ACCEPTED_MIME_TYPES = {
  "image/*": ["image/jpeg", "image/jpg", "image/png", "image/webp"],
  "video/*": ["video/mp4", "video/webm", "video/ogg"],
} as const;

export const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
  (
    {
      id,
      label,
      className,
      initialFiles,
      onChange,
      compact,
      acceptedFileTypes = ["image/*"],
      browseText = "Tilføj filer for at uploade eller",
      error,
      required,
      sizeLimitMB = 70,
      fileLimit,
      enableReorder,
      acceptedOrientations,
      aspectRatioClass,
      showPrimary,
      hideBackgroundMedia,
    },
    ref
  ) => {
    const acceptedMimeTypes = acceptedFileTypes.flatMap(
      (type) => ACCEPTED_MIME_TYPES[type]
    );
    const {
      fileList,
      dragState,
      onDragEnter,
      onDragLeave,
      onFileDrop,
      value,
      onDeleteClick,
      onReorder,
      isUploading,
    } = useFileUpload({
      sizeLimitMB,
      initialFiles,
      onChange,
      fileLimit,
      acceptedOrientations,
      acceptedMimeTypes,
    });
    const acceptedExtensions = acceptedMimeTypes.map(
      (type) => type.split("/")[1]
    );

    const [exampleMedia, setExampleMedia] = useState<
      StrapiMedia | null | undefined
    >(null);

    const showBackground = fileList.length && !isUploading;

    return (
      <>
        <div className={classNames("relative", className)}>
          <label htmlFor={id} className="mb-1.5 text-xs text-secondary-600">
            {required ? label + "*" : label}
          </label>
          <div
            className={classNames(
              "rounded-md focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500",
              error && "ring-2 ring-danger-500"
            )}
          >
            <div
              className={classNames(
                "relative group text-secondary-600 border-2 border-dashed border-secondary-200 rounded-md px-5",
                dragState === "enter" && "bg-secondary-100",
                dragState === "incorrect" && "bg-primary-50 border-danger-500",
                compact ? "py-8 lg:py-12" : "py-24 lg:py-36",
                showBackground && "border-b-0 rounded-b-none py-8 lg:py-8"
              )}
            >
              <p
                className={classNames(
                  "select-none pointer-events-none text-center",
                  dragState === "incorrect" && "text-danger-500",
                  showBackground && "mb-10"
                )}
              >
                <Icon name="uploadCloud" className="w-12 h-12 mx-auto mb-3" />
                {dragState === "incorrect" ? (
                  "En eller flere filer har den forkerte filtype!"
                ) : (
                  <>
                    {browseText}{" "}
                    <span className="text-primary-500 underline underline-offset-4">
                      gennemse
                    </span>
                  </>
                )}
              </p>
              {!hideBackgroundMedia && (
                <BackgroundMedia
                  className={aspectRatioClass}
                  fileList={fileList}
                />
              )}
              <input
                ref={ref}
                type="file"
                name="files"
                id={id}
                onChange={onFileDrop}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                className="absolute inset-0 z-10 w-full h-full opacity-0 cursor-pointer"
                multiple={Boolean(fileLimit && fileLimit > 1)}
                accept={acceptedMimeTypes.join(", ")}
                value={value}
              />
            </div>
            <UploadList
              onReorder={enableReorder ? onReorder : undefined}
              onDeleteClick={onDeleteClick}
              fileList={fileList}
              onExampleClick={(fileId) => {
                setExampleMedia(
                  (fileList as StrapiMedia[]).find((file) => file.id === fileId)
                );
              }}
              showPrimary={showPrimary}
              aspectRatioClass={aspectRatioClass}
            />
          </div>
          {error && (
            <p className="text-xs text-danger-500 mt-1">{error.message}</p>
          )}
          <MediaExampleDialog
            exampleMedia={exampleMedia}
            aspectRatioClass={aspectRatioClass}
            onOpenChange={(open) => !open && setExampleMedia(null)}
          />
        </div>
        <small className="text-secondary-800 text-xs">
          Accepterer følgende filformater: {acceptedExtensions.join(", ")}.
        </small>
      </>
    );
  }
);
