/**
 * Asynchronously evaluates whether any element in the provided array satisfies the provided asynchronous testing function.
 * This is an asynchronous version of Array.prototype.some. It processes each element with the provided async predicate
 * function and returns true if at least one asynchronous operation resolves to true. If all operations resolve to false,
 * or the array is empty, it returns false.
 *
 * @example
 * // Example with async operation
 * const users = [{ id: 1 }, { id: 2 }, { id: 3 }];
 * const userExists = async (user) => await checkUserInDatabase(user.id);
 * asyncSome(users, userExists).then(console.log); // true or false based on database response
 *
 * @template T The type of the elements in the array.
 * @param {Array<T>} array The array to process.
 * @param {(item: T) => Promise<boolean>} predicate A function that takes an element of the array and returns a Promise
 * which resolves to a boolean. This function is applied to each element in the array asynchronously.
 * @returns {Promise<boolean>} A Promise that resolves to true if the predicate function returns a truthy value for any
 * array element; otherwise, false.
 */
export const asyncSome = async <T>(
  array: Array<T>,
  predicate: (item: T) => Promise<boolean>
) => {
  for (const item of array) {
    if (await predicate(item)) return true;
  }
  return false;
};
