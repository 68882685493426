import { AxiosProgressEvent } from "axios";
import Icon from "~/assets/icons/Icon";

export function UploadProgress({
  name,
  upload,
}: {
  name: string;
  upload: AxiosProgressEvent | null | undefined;
}) {
  if (!upload?.progress) return null;

  const timeLeftString = upload.estimated?.toFixed(0)
    ? `- ${upload.estimated.toFixed(0)} sekunder tilbage`
    : "";

  return (
    <div className="relative flex items-center justify-between border-2 border-secondary-200 p-3 border-b-0 last:border-b-2 last:rounded-b-md">
      <div className="flex flex-col">
        <p>{name}</p>
        <p className="text-secondary-600 text-xs">
          {(upload?.progress * 100).toFixed(0)}% {timeLeftString}
        </p>
      </div>
      <Icon name="spinner" className="animate-spin w-6 h-6 mr-2" />
      <div className="absolute inset-0 -z-10">
        <div
          className="bg-secondary-200 h-full"
          style={{ width: `${upload.progress * 100}%` }}
        />
      </div>
    </div>
  );
}
