import classNames from "classnames";
import { PropsWithChildren } from "react";

type InfoBoxProps = PropsWithChildren & {
  id?: string;
  className?: string;
};

const InfoBoxIcon = () => (
  <span className="rounded-full bg-secondary-200 text-neutral-900 flex justify-center items-center w-[16px] h-[16px] text-2xs font-semibold">
    i
  </span>
);

export const InfoBox = ({ children, className, id }: InfoBoxProps) => {
  return (
    <div
      id={id}
      className={classNames(
        "flex flex-col gap-3 justify-between bg-secondary-900 text-secondary-500 rounded-lg p-5",
        className
      )}
    >
      <div className="flex items-center gap-2 text-xs text-secondary-200">
        <InfoBoxIcon />
        Værd at vide
      </div>
      {children}
    </div>
  );
};
