import {
  Root,
  Viewport,
  Scrollbar,
  Thumb,
  Corner,
} from "@radix-ui/react-scroll-area";
import classNames from "classnames";

type ScrollAreaProps = {
  children: React.ReactNode;
  className?: string;
};

export function ScrollArea({
  children,
  className,
  ...restProps
}: ScrollAreaProps & React.ComponentProps<typeof Root>) {
  return (
    <Root
      className={classNames("h-full overflow-hidden", className)}
      {...restProps}
    >
      <Viewport className="w-full h-full">{children}</Viewport>
      <ScrollbarElement orientation="vertical" />
      <ScrollbarElement orientation="horizontal" />
      <Corner className="bg-secondary-100/75" />
    </Root>
  );
}

const ScrollbarElement = ({
  orientation,
}: {
  orientation: "horizontal" | "vertical";
}) => (
  <Scrollbar
    className="group flex select-none touch-none p-0.5 bg-secondary-100/75 hover:bg-secondary-100 transition-colors duration-150 ease-out data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
    orientation={orientation}
  >
    <Thumb className="flex-1 bg-secondary-300/75 group-hover:bg-secondary-300 rounded-[10px] relative z-10 before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
  </Scrollbar>
);
