import { DeliveryType } from "~/shared-types";
import { Orientation } from "./getMediaSize";

export function getDeliveryTypeInDanish(deliveryType: DeliveryType) {
  if (deliveryType === "shipping") return "Fragt";
  if (deliveryType === "pickup") return "Afhentning";
  if (deliveryType === "freeShipping") return "Fragt";
  if (deliveryType === "shipmondo") return "Shipmondo";
}

export const getDanishOrientation = (orientation: Orientation) => {
  if (orientation === "landscape") return "bredformat";
  if (orientation === "portrait") return "højformat";
  if (orientation === "square") return "kvadratisk";
};
