/* istanbul ignore file */

import { Tags, load as exifReaderLoad } from "exifreader";
import { getMediaSize } from "../getMediaSize";

export type OrientationObject = {
  rotate: number;
  flip: boolean;
  origin: string;
};

const ORIENTATIONS: Record<number, OrientationObject> = {
  1: { rotate: 0, flip: false, origin: "top-left" },
  2: { rotate: 0, flip: true, origin: "top-right" },
  3: { rotate: 180, flip: false, origin: "bottom-right" },
  4: { rotate: 180, flip: true, origin: "bottom-left" },
  5: { rotate: 90, flip: true, origin: "top-left" },
  6: { rotate: 90, flip: false, origin: "top-right" },
  7: { rotate: 270, flip: true, origin: "bottom-right" },
  8: { rotate: 270, flip: false, origin: "bottom-left" },
};

// returns dx and dy for the origin of the image
export const getOriginFromOrientation = (orientation: OrientationObject) => {
  const { origin } = orientation;
  const [x, y] = origin.split("-");

  return { dx: x === "top" ? 0 : 1, dy: y === "left" ? 0 : 1 };
};

export const orientateImageFile = async (
  imageFile: File,
  document: Document
) => {
  const { width, height } = await getMediaSize(imageFile);
  const { Orientation } = await exifReaderLoad(imageFile).catch(
    () => ({} as Tags)
  );

  if (!Orientation || Orientation.value === 1) return imageFile;

  const orientation = ORIENTATIONS[Orientation.value as number];

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (!context) return imageFile;

  canvas.width = width;
  canvas.height = height;

  return getImageFromCanvas(orientation, context, canvas, imageFile);
};

export const getImageFromCanvas = (
  orientation: OrientationObject,
  context: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  imageFile: File
) =>
  new Promise<File>((resolve, reject) => {
    context.translate(canvas.width / 2, canvas.height / 2);

    const image = new Image();

    // Add event listener for when image has loaded
    image.onload = () => {
      const { dx, dy } = getOriginFromOrientation(orientation);

      context.drawImage(
        image,
        -canvas.width / 2 + dx,
        -canvas.height / 2 + dy,
        canvas.width,
        canvas.height
      );

      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }

        const file = new File([blob], imageFile.name, { type: imageFile.type });
        resolve(file); // Resolve the promise with the File object
      }, imageFile.type);
    };
    image.onerror = reject; // Reject the promise on image loading error
    image.src = URL.createObjectURL(imageFile);
  });
