// istanbul ignore file

export type Orientation = "landscape" | "portrait" | "square";

export const getMediaSize = async (
  mediaFile: File
): Promise<{
  width: number;
  height: number;
  orientation: Orientation;
}> => {
  if (mediaFile.type.startsWith("image/")) {
    return getImageSize(mediaFile);
  } else if (mediaFile.type.startsWith("video/")) {
    return getVideoSize(mediaFile);
  } else {
    throw new Error("Unsupported media type");
  }
};

const loadMedia = (
  element: HTMLImageElement | HTMLVideoElement,
  file: File
) => {
  return new Promise<void>((resolve, reject) => {
    const objectURL = URL.createObjectURL(file);
    const onLoadHandler = () => {
      URL.revokeObjectURL(objectURL);
      document.body.removeChild(element);
      resolve();
    };

    // for images
    element.onload = onLoadHandler;
    // for videos
    element.onloadedmetadata = onLoadHandler;

    element.onerror = () => {
      URL.revokeObjectURL(objectURL);
      document.body.removeChild(element);
      reject(new Error(`Error loading ${element.tagName.toLowerCase()}`));
    };
    element.src = objectURL;
    element.style.display = "none";
    document.body.appendChild(element);
  });
};

const getImageSize = async (imageFile: File) => {
  const img = document.createElement("img");
  await loadMedia(img, imageFile);
  return {
    width: img.width,
    height: img.height,
    orientation: getOrientation(img.width, img.height),
  };
};

const getVideoSize = async (videoFile: File) => {
  const video = document.createElement("video");
  await loadMedia(video, videoFile);
  return {
    width: video.videoWidth,
    height: video.videoHeight,
    orientation: getOrientation(video.videoWidth, video.videoHeight),
  };
};

export const getOrientation = (width: number, height: number): Orientation => {
  const aspectRatio = width / height;

  let orientation: Orientation = "landscape";
  if (aspectRatio < 1) orientation = "portrait";
  if (aspectRatio === 1) orientation = "square";

  return orientation;
};
