import classNames from "classnames";
import { ReactNode } from "react";

export const errorBorder = "ring-2 ring-danger-500";

export const ariaControlledBorder =
  "aria-[invalid=true]:ring-2 aria-[invalid=true]:ring-danger-500";

export function InputError({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) {
  if (!children) return null;

  return (
    <span
      className={classNames(
        "block mt-1 text-xs font-normal text-danger-500",
        className
      )}
    >
      {children}
    </span>
  );
}
