import classNames from "classnames";
import Image from "next/image";
import { StrapiImage, StrapiMedia, StrapiVideo } from "~/shared-types";
import { getFormattedImageAttributes } from "~/utils/getImageAttributes";
import { getMediaType } from "~/utils/getMediaType";
import { getVideoUrl } from "~/utils/getVideoUrl";
import { MediaFile } from "~/utils/uploadFiles";

type Props = {
  fileList: MediaFile[];
  className?: string;
};

export const BackgroundMedia = ({ fileList, className }: Props) => {
  const firstMedia = fileList.find((file) => "attributes" in file) as
    | StrapiMedia
    | undefined;

  if (!firstMedia) return null;

  const mediaType = getMediaType(firstMedia);

  if (mediaType === "other") return null;

  return mediaType === "image" ? (
    <Image
      className={classNames("mx-auto rounded-xl object-cover", className)}
      {...getFormattedImageAttributes(firstMedia as StrapiImage, "large")}
    />
  ) : (
    <video
      preload="metadata"
      autoPlay
      muted
      loop
      className={classNames("mx-auto rounded-xl object-cover", className)}
      src={getVideoUrl(firstMedia as StrapiVideo)}
    />
  );
};
