import { StrapiImage, StrapiMedia, StrapiVideo } from "~/shared-types";
import { Dialog } from "../../Dialog";
import Image from "next/image";
import { getFormattedImageAttributes } from "~/utils/getImageAttributes";
import { getMediaType } from "~/utils/getMediaType";
import { getVideoUrl } from "~/utils/getVideoUrl";
import classNames from "classnames";
import { Badge } from "../../Badge";
import { getAspectRatioClass } from "~/utils/getAspectRatioClass";

type Props = {
  exampleMedia?: StrapiMedia | null;
  onOpenChange: (open: boolean) => void;
  aspectRatioClass?: string;
};

export const MediaExampleDialog = ({
  exampleMedia,
  onOpenChange,
  aspectRatioClass,
}: Props) => {
  const mediaType = getMediaType(exampleMedia);

  if (mediaType === "other") return null;

  const calculateAspectRatioClass = () => {
    if (mediaType !== "image" || aspectRatioClass) return aspectRatioClass;

    // Use the image's aspect ratio as fallback
    return getAspectRatioClass(exampleMedia as StrapiImage);
  };

  return (
    <Dialog
      trigger={null}
      open={Boolean(exampleMedia)}
      onOpenChange={onOpenChange}
      closeClassName="bg-secondary-200 hover:bg-secondary-300"
      contentClassName={classNames(
        "relative mx-auto rounded-xl",
        calculateAspectRatioClass()
      )}
      className={classNames("py-6 px-6 sm:py-12", calculateAspectRatioClass())}
    >
      <Badge className="absolute top-2 left-2 w-[70%] mb-2 !text-2xs sm:!text-xs text-secondary-600">
        Mediet vil blive vist i dette format
      </Badge>

      {mediaType === "image" ? (
        <Image
          {...getFormattedImageAttributes(exampleMedia as StrapiImage, "large")}
          className={classNames(
            calculateAspectRatioClass(),
            "w-full rounded-xl object-cover"
          )}
        />
      ) : (
        <video
          src={getVideoUrl(exampleMedia as StrapiVideo)}
          controls
          title={exampleMedia?.attributes.name}
          className={classNames(
            calculateAspectRatioClass(),
            "w-full rounded-xl"
          )}
        />
      )}
    </Dialog>
  );
};
