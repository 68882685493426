import React from "react";
import { Root, Item, Indicator } from "@radix-ui/react-radio-group";
import { Control, Controller, FieldError } from "react-hook-form";
import {
  CompoundComponentContext,
  useCompoundComponent,
} from "~/hooks/useCompoundComponent";
import { InputError } from "../InputError";

type RadioGroupProps = {
  children: React.ReactNode;
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  onValueChange?: (value: string) => void;
  error?: FieldError;
} & React.ComponentProps<typeof Root>;

export const RadioGroup = ({
  children,
  id,
  control,
  onValueChange,
  error,
  ...restProps
}: RadioGroupProps) => (
  <Controller
    name={id}
    control={control}
    render={({ field }) => (
      <Root
        className="flex flex-col gap-1.5"
        value={field.value}
        onValueChange={(value) => {
          field.onChange(value);
          onValueChange?.(value);
        }}
        {...restProps}
      >
        <CompoundComponentContext.Provider value={"RadioGroup"}>
          {children}
        </CompoundComponentContext.Provider>
        <InputError>{error?.message}</InputError>
      </Root>
    )}
  />
);

RadioGroup.Item = ({
  value,
  children,
  id,
  disabled,
}: {
  value: string;
  children: React.ReactNode;
  id?: string;
  disabled?: boolean;
}) => {
  useCompoundComponent("RadioGroup", "Item");
  return (
    <label
      className={`text-xs flex items-center gap-4  ${
        disabled ? "cursor-not-allowed text-neutral-500" : "cursor-pointer"
      }`}
    >
      <Item
        className={`w-[22px] h-[22px] rounded-full border border-secondary-300 focus-visible:shadow-[0_0_0_2px] focus-visible:shadow-black outline-none ${
          disabled ? "bg-secondary-300 cursor-not-allowed" : "bg-white"
        }`}
        value={value}
        id={id}
        disabled={disabled}
      >
        <Indicator
          className={`flex items-center justify-center w-full h-full rounded-full relative border border-primary-500 bg-primary-500 after:content-[''] after:block after:w-2.5 after:h-2.5 after:rounded-full after:bg-white ${
            disabled ? "bg-secondary-300 cursor-not-allowed" : ""
          }`}
        />
      </Item>
      {children}
    </label>
  );
};
