import Image from "next/image";
import Icon from "~/assets/icons/Icon";
import { StrapiImage, StrapiVideo } from "~/shared-types";
import { getFormattedImageAttributes } from "~/utils/getImageAttributes";
import { getMediaType } from "~/utils/getMediaType";
import { Badge } from "../Badge";
import classNames from "classnames";
import { getVideoUrl } from "~/utils/getVideoUrl";
import { getAspectRatioClass } from "~/utils/getAspectRatioClass";
import { useMemo } from "react";

export function UploadedMedia({
  media,
  onDeleteClick,
  onReorder,
  onExampleClick,
  isPrimary,
  aspectRatioClass,
}: {
  media: StrapiImage | StrapiVideo | null;
  onDeleteClick: (fileId: number) => void;
  onReorder?: (direction: "up" | "down") => void;
  onExampleClick: (fileId: number) => void;
  isPrimary?: boolean;
  aspectRatioClass?: string;
}) {
  if (!media) return null;

  return (
    <div
      className={classNames(
        "grid grid-cols-1 xs:grid-cols-[3fr_1fr] bg-secondary-200 border p-3 last:border-b-2 last:rounded-b-md",
        isPrimary ? "border-success" : "border-secondary-200"
      )}
    >
      <div
        className={classNames(
          "grid gap-2 items-center",
          onReorder ? "grid-cols-12" : "grid-cols-6"
        )}
      >
        {onReorder && (
          <div className="flex flex-col">
            <button
              title="Ryk medie op"
              className="hover:bg-secondary-300 rounded-t-md rounded-b-sm"
              onClick={() => onReorder("up")}
            >
              <Icon className="mx-auto w-5 rotate-180" name="chevronDown" />
            </button>
            <button
              title="Ryk medie ned"
              className="hover:bg-secondary-300 rounded-b-md rounded-t-sm"
              onClick={() => onReorder("down")}
            >
              <Icon className="mx-auto w-5" name="chevronDown" />
            </button>
          </div>
        )}
        <DoneMedia
          aspectRatioClass={aspectRatioClass}
          onReorder={onReorder}
          isPrimary={isPrimary}
          media={media}
        />
      </div>

      <div className="flex items-center gap-2 justify-end">
        <button
          className="text-2xs sm:text-xs"
          title="Se eksempel"
          onClick={() => onExampleClick(media.id)}
        >
          Se eksempel
        </button>
        <button title="Slet medie" onClick={() => onDeleteClick(media.id)}>
          <Icon name="trash" className="w-6 h-6 mr-2" />
        </button>
      </div>
    </div>
  );
}

type DoneMediaProps = {
  media: StrapiImage | StrapiVideo;
  isPrimary?: boolean;
  onReorder?: (direction: "up" | "down") => void;
  aspectRatioClass?: string;
};

export function DoneMedia(props: DoneMediaProps) {
  const { media, isPrimary, onReorder } = props;

  const mediaType = getMediaType(media);

  const aspectRatioClass = useMemo(() => {
    if (mediaType !== "image") return "aspect-square";

    if (props.aspectRatioClass) return props.aspectRatioClass;

    return getAspectRatioClass(media as StrapiImage);
  }, [props.aspectRatioClass, mediaType]);

  const className = classNames(
    "rounded-md object-cover object-center w-full",
    onReorder && "col-span-2",
    aspectRatioClass
  );
  return (
    <>
      {mediaType === "image" ? (
        <DoneImage className={className} media={media as StrapiImage} />
      ) : (
        <DoneVideo className={className} media={media as StrapiVideo} />
      )}
      <div className={classNames(onReorder ? "col-span-9" : "col-span-5")}>
        <p className="text-secondary-600 text-xs col-span-5 line-clamp-1">
          {media.attributes.name}
        </p>
        {isPrimary && (
          <Badge className="mt-1" color="green">
            Fremhævet
          </Badge>
        )}
      </div>
    </>
  );
}
export function DoneImage({
  media,
  className,
}: {
  media: StrapiImage;
  className?: string;
}) {
  const { name, formats } = media.attributes;

  return (
    <Image
      {...getFormattedImageAttributes(
        media,
        formats?.thumbnail ? "thumbnail" : "large",
        { altFallback: name }
      )}
      className={className}
    />
  );
}
export function DoneVideo({
  media,
  className,
}: {
  media: StrapiVideo;
  className?: string;
}) {
  const { name } = media.attributes;

  return (
    <video
      title={name}
      preload="metadata"
      src={getVideoUrl(media)}
      className={className}
    />
  );
}
