import React from "react";
import { UseFormRegister, RegisterOptions } from "react-hook-form";
import UncontrolledInput, {
  UncontrolledInputProps,
} from "../UncontrolledInput";

export type InputProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  registerOptions?: RegisterOptions;
} & UncontrolledInputProps;

const Input = (props: InputProps) => {
  const { id, register, registerOptions, ...restProps } = props;

  const { ref, ...registerProps } = register(id, registerOptions);

  return (
    <UncontrolledInput
      ref={ref}
      id={id}
      registerOptions={registerOptions}
      {...restProps}
      {...registerProps}
    />
  );
};

export default Input;
